
import "./Applications.css"
import applicationList from "./applicationListItems.js";

function Applications(){
    return (
        <section className="industry-applications-section" id="applications">
            <div className="top">
                <h1>Industry Applications</h1>
                <p>Tailored Solutions for Various Sectors</p>
            </div>
            <div className="applicaitons-content">
                {
                    applicationList.map((data, idx)=>(
                        <div key={idx} className="content-item">
                            <img src={data.img} alt={data.title} />
                            <div className="details">
                                <h4>{data.title}</h4>
                                <p>{data.description}</p>
                            </div>
                        </div>
                    ))
                }
            </div>
        </section>
    );
}

export default Applications;