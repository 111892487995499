
import innovative from "../../assets/about/innovative.png";
import industry from "../../assets/about/industry.png";
import client from "../../assets/about/client.png";
import expert from "../../assets/about/expert.png";

const itemsList = [
    {
        img: innovative,
        title: "Innovative IT Solutions",
        description: "AI, Data Analytics, IoT, Application Development"
    },
    {
        img: industry,
        title: "Industry Focus",
        description: "Manufacturing, Mines, Energy, Shipping"
    },
    {
        img: client,
        title: "Client Advantage",
        description: "Staying ahead of the competition"
    },
    {
        img: expert,
        title: "Expert Services",
        description: "Leveraging cutting-edge technology"
    }
]

export default itemsList;