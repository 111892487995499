import { useState } from "react";
import { HashLink } from "react-router-hash-link";

import { FaBars, FaTimes } from 'react-icons/fa';

import "./Nav.css";
import Logo from "../../assets/logo.png"

function Nav() {

    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = ()=>{
        setIsOpen(!isOpen);
    }

    return (
        <nav>
            <div className="flex flex-wrap items-center justify-between lg:mx-8">
                <HashLink smooth to="#" className="flex items-center">
                    <img src={Logo} className="h-[60px] w-[60px] ml-4 lg:ml-0 2xl:h-[100px] 2xl:w-[100px]" alt="Datanetrix" />
                </HashLink>
                <button type="button" onClick={toggleMenu} 
                className="inline-flex items-center p-2 w-10 h-10 text-[32px] justify-center text-gray-500 rounded-lg lg:hidden">
                    {isOpen ? <FaTimes /> : <FaBars />}
                </button>
                <div className={`${isOpen ? "block" : "hidden"} w-full max-lg:h-[434px] lg:block lg:w-auto`}>
                    <ul className="nav-items">
                        <li>
                            <HashLink smooth to="#home" onClick={() => setIsOpen(false)}>Home</HashLink>
                        </li>
                        <li>
                            <HashLink smooth to="#about" onClick={() => setIsOpen(false)}>About Us</HashLink>
                        </li>
                        <li>
                            <HashLink smooth to="#services" onClick={() => setIsOpen(false)}>Services</HashLink>
                        </li>
                        <li>
                            <HashLink smooth to="#solutions" onClick={() => setIsOpen(false)}>Solutions</HashLink>
                        </li>
                        <li>
                            <HashLink smooth to="#applications" onClick={() => setIsOpen(false)}>Applications</HashLink>
                        </li>
                        <li>
                            <HashLink smooth to="#location" onClick={() => setIsOpen(false)}>Location</HashLink>
                        </li>
                        <li>
                            <HashLink smooth to="#contact" onClick={() => setIsOpen(false)}>Contact Us</HashLink>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>

    );
}

export default Nav;