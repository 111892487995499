
import cbm from "../../assets/solutions/condition_based_monitoring.png";
import udp from "../../assets/solutions/unified_data_platform.png";
import ec from "../../assets/solutions/energy_conservation.png";

const solutionList = [
    {
        img: cbm,
        title: "Condition Based Monitoring",
        description: "Optimize machinery performance with real-time data and predictive analytics"
    },
    {
        img: udp,
        title: "Unified Data Platform",
        description: "Seamlessly integrate and analyze data across your enterprise"
    },
    {
        img:  ec,
        title: "Energy Conservation",
        description: "Reduce costs and improve efficiency with AI-driven energy management"
    }
];

export default solutionList;