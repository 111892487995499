
import ai from "../../assets/services/ai.png"
import iot from "../../assets/services/iot.png"
import da from "../../assets/services/da.png"
import ad from "../../assets/services/app-dev.png"

const serviceList = [
    {
        img: ai,
        title: "Artificial Intelligence",
        description: "Revolutionize your business with intelligent solutions like Computer Vision and Natural Language Processing"
    },
    {
        img: iot,
        title: "IoT Solutions",
        description: "Monitor and optimize energy usage with our advanced IoT systems"
    },
    {
        img: da,
        title: "Data Analytics",
        description: "Turn data into actionable insights for better decision-making"
    },
    {
        img: ad,
        title: "Application Development",
        description: "Build responsive websites and mobile applications tailored to your needs"
    },
];

export default serviceList;