import { HashLink } from "react-router-hash-link";

import "./Hero.css";

function Hero() {
    return (
        <section className="hero-section" id="home">
            <div className="content">
                <h1>Empowering Industry 4.0 Innovation</h1>
                <h4>Unleashing the Power of Data with Advanced AI, IoT, and Analytics Solutions</h4>
                <HashLink smooth to="#contact">Contact Us</HashLink>
            </div>
        </section>
    );
}

export default Hero;