
import { Link } from "react-router-dom";
import { FaInstagram, FaLinkedin  } from "react-icons/fa";

import Logo from "../../assets/logo.png"

import "./Footer.css";

function Footer(){
    return (
        <footer>
            <div className="footer-contents">
                <div className="logo">
                    <img src={Logo} alt="Datanetrix" />
                    {/* <div className="social">
                        <span>
                            <a target="_blank" href="https://www.instagram.com/datanetrix/"><FaInstagram /></a>
                        </span>
                        <span>
                            <a target="_blank" href="https://www.linkedin.com/company/datanetrix/about/"><FaLinkedin /></a>
                        </span>
                    </div> */}
                </div>
            </div>
            {/* <h1>DATANETRIX</h1> */}
            <p className="copy">All Rights Reserved by Datanetrix @2024</p>
        </footer>
    );
}

export default Footer;