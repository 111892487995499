
import "./Services.css"
import serviceList from "./serviceListItems.js";

function Services(){
    return (
        <section className="our-services-section" id="services">
            <div className="top">
                <h1>Our Services</h1>
                <p>Comprehensive IT Services Tailored for Modern Industries</p>
            </div>
            <div className="service-content">
                {
                    serviceList.map((data, idx)=>(
                        <div key={idx} className="content-item">
                            <img src={data.img} alt={data.title} />
                            <div className="details">
                                <h4>{data.title}</h4>
                                <p>{data.description}</p>
                            </div>
                        </div>
                    ))
                }
            </div>
        </section>
    );
}

export default Services;