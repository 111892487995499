
import manufacturing from "../../assets/applications/manufacturing.png";
import mines from "../../assets/applications/mines.png";
import oilgas from "../../assets/applications/oil-gas.png";
import shipping from "../../assets/applications/shipping-marine.png";

const applicationList = [
    {
        img: manufacturing,
        title: "Manufacturing",
        description: "Enhance production quality and efficiency with AI and IoT"
    },
    {
        img: mines,
        title: "Mines",
        description: "Improve safety and operational efficiency through advanced monitoring"
    },
    {
        img: oilgas,
        title: "Oil & Gas",
        description: "Optimize resource management and reduce risks with AI"
    },
    {
        img: shipping,
        title: "Shipping & Marine",
        description: "Ensure timely deliveries and reduce fuel consumption with predictive analytics"
    },
];

export default applicationList;