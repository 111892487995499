

import about from "../../assets/about/about-img.png";
import "./About.css";

import itemsList from "./aboutItemsList";

function About(){
    return (
        <section className="about-us" id="about">
            <div className="about-content">
                <h1>About Us</h1>
                <p>
                    At Datanetrix, we offer innovative IT solutions in AI, Data Analytics, IoT, 
                    and Application Development for various industries. Our mission is to keep 
                    clients ahead with cutting-edge technology and expert services.
                </p>
            </div>

            <div className="about-image">
                <img src={about} alt="About Us" />
            </div>
            
            <AboutItems />
        </section>
    );
}

function AboutItems(){
    return(
        <div className="about-items-list">
            {
                itemsList.map((data,idx)=>(
                    <div key={idx} className="items">
                        <img src={data.img} alt={data.title} />
                        <div className="text grid gap-1">
                            <h4 className="text-base lmd:text-lg font-bold">{data.title}</h4>
                            <p>{data.description}</p>
                        </div>
                    </div>
                ))
            }
        </div>
    );
}

export default About;