

import "./Location.css";

function Location(){
    return (
        <section className="location-section" id="location">
            <div className="top">
                <h1>Where are We?</h1>
            </div>
            <div className="location-content">
                <div className="map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d486416.95439884125!2d82.93293171066571!3d17.738396991828747!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a39431389e6973f%3A0x92d9c20395498468!2sVisakhapatnam%2C%20Andhra%20Pradesh!5e0!3m2!1sen!2sin!4v1722506029141!5m2!1sen!2sin" 
                allowFullScreen="" loading="lazy" 
                referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
                <div className="address">
                    <h2>Location</h2>
                    <h5>Yendada </h5>
                    <h5>Visakhapatnam</h5>
                    <h5>Andhra Pradesh</h5>
                    <h5>India</h5>
                </div>
            </div>
        </section>
    );
}

export default Location;