import React, { useState } from 'react';
import emailjs from 'emailjs-com';

import "./Contact.css";

function Contact(){

    const [formData, setFormData] = useState({
        full_name: '',
        email: '',
        phone: '',
        message: ''
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        emailjs.sendForm(
            "service_iv8f9or",
            "template_gci1tcv",
            e.target,
            'J5fHGgg8oPcsN7YcV'
        )
        .then((result) => {
            setFormData({
                full_name: '',
                email: '',
                phone: '',
                message: ''
            });
            alert("Email sent successfully");
        }, (error) => {
            console.error("There was an error sending the email:", error);
        });
    };

    return (
        <section className="contact-section" id="contact">
            <div className="title">
                <h1>Get in Touch</h1>
                <p>We're Here to Help You Innovate</p>
            </div>
            <form className="contact-form" onSubmit={handleSubmit}>
                <div className="form-content">
                    <div className="left-content">
                        <div className="form-item">
                            <label className="form-label" htmlFor="full_name">Full Name</label>
                            <input className="form-input" type="text" 
                            name="full_name" id="full_name" 
                            placeholder="Enter your Name" 
                            value={formData.full_name} onChange={handleChange}
                            required />
                        </div>
                        <div className="form-item">
                            <label className="form-label" htmlFor="email">Email ID</label>
                            <input className="form-input" type="email" 
                            name="email" id="email" 
                            placeholder="Enter your email ID" 
                            value={formData.email} onChange={handleChange}
                            required />
                        </div>
                        <div className="form-item">
                            <label className="form-label" htmlFor="phone">Phone Number</label>
                            <input className="form-input" type="text" 
                            name="phone" id="phone" 
                            placeholder="Enter your Phone Number" pattern="[0-9]{10}" 
                            value={formData.phone} onChange={handleChange}
                            required />
                        </div>
                    </div>
                    <div className="right-content">
                        <div className="form-item">
                            <label className="form-label" htmlFor="message">How can We Assist You?</label>
                            <textarea className="form-textarea" 
                            name="message" id="message" 
                            placeholder="Write your answer here" 
                            value={formData.message} onChange={handleChange}
                            required></textarea>
                        </div>
                    </div>
                </div>
                <button type="submit">SUBMIT</button>
            </form>
        </section>
    );
}

export default Contact;