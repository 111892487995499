
import "./Solutions.css";

import solutionList from "./solutionListItems.js";

function Solutions(){
    return (
        <section className="solution-section" id="solutions">
            <div className="top">
                <h1>Our Innovative Solutions</h1>
                <p>Cutting-Edge Solutions for Modern Challenges</p>
            </div>
            <div className="solution-content">
                {
                    solutionList.map((data, idx)=>(
                        <div key={idx} className="solution-item">
                            <img src={data.img} alt={data.title} />
                            <div className="text-content">
                                <h4>{data.title}</h4>
                                <p>{data.description}</p>
                            </div>
                        </div>
                    ))
                }
            </div>
        </section>
    );
}

export default Solutions