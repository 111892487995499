
import { BrowserRouter } from 'react-router-dom';
import Nav from './component/navbar/Nav';
import Hero from './section/hero/Hero';
import About from './section/about/About';
import Services from './section/services/Services';
import Solutions from './section/solutions/Solutions';
import Applications from './section/applications/Applications';
import Location from './section/location/Location';
import Contact from './section/contact/Contact';
import Footer from "./component/footer/Footer";

import './App.css';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Nav />
        <div className='App-Body'>
          <Hero />
          <About />
          <Services />
          <Solutions />
          <Applications />
          <Location />
          <Contact />
        </div>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
